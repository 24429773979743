import * as React from "react"

import Button, { ButtonOutline, ButtonSecondary } from '../components/button';
import { Row } from "react-bootstrap";

import * as strings from '../branding/strings';
import { Branding } from "../App";

type Props = {
  branding: Branding,
}

function Home(props: Props) {
  return (
    <div className="centered-container mx-auto px-2">
      <img src={props.branding.logoPath} className="App-logo mb-3 mx-auto" alt="logo" />
      <h2 className="mb-3">{props.branding.homeH1}</h2>
      <p className="mb-4">{props.branding.homeH2}</p>
      {/* <p className="mb-4 lead">{strings.optionsP}</p> */}

      <p className="mb-2">{props.branding.option1P}</p>
      <div className="container mb-4">
        <Button text={props.branding.option1BText} link={props.branding.option1BLink} eventId="1PWASDB6" />
      </div>

      <p className="mb-2">{props.branding.option2P}</p>
      <div className="container mb-5">
        <Button text={props.branding.option2BText} link={props.branding.option2BLink} eventId="75GZMUXV" />
      </div>

      {props.branding.serviceLogoPath !== "" && (
        <img src={props.branding.serviceLogoPath} className="Service-logo mb-3 mx-auto d-block" alt="service logo" />
      )}
      {/* <p className="mb-4 question">{strings.satisfiedQuestion}</p>
      <div className="container">
        <a
          className="mx-2"
          href="https:reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button text="Yes" link="/reviewOptions" />
        </a>
        <a
          className="mx-2"
          href="https:reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button text="No" link="/feedbackDecision" />
        </a>
      </div> */}
    </div>
  );
}

export default Home;