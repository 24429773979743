import * as React from "react"

import { Container, Form, Button } from "react-bootstrap";

import * as strings from '../branding/strings';
import { Branding } from "../App";

type Props = {
  branding: Branding,
}

function Feedback(props: Props) {
  return (
    <div>
      <h2 className="mb-4">{strings.leaveFeedbackH1}</h2>

      <Container className="styled-form px-3 pb-5 text-start form-text">
        <Form>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Name</Form.Label>
            <Form.Control type="name" placeholder="Enter name" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPhone">
            <Form.Label>Contact number</Form.Label>
            <Form.Control type="phone" placeholder="Enter phone number" />
            <Form.Text className="text-muted">
              We'll never share your phone number with anyone else.
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formFeedback">
            <Form.Label>Feedback</Form.Label>
            <Form.Control as="textarea" rows={5} />
          </Form.Group>

          <div className="text-end py-2">
            <Button className="text-end" variant="outline-secondary" type="submit">
              Submit
            </Button>
          </div>
        </Form>
      </Container>
    </div>
  );
}

export default Feedback;