import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, NavLink, Link } from "react-router-dom";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import * as Fathom from 'fathom-client';

import './App.css';
import './branding/brandOverrides.scss';

import Footer from "./sections/footer";

import HomePage from './pages/home';
import ReviewHelper from './pages/reviewHelper';
import ReviewOptionsPage from './pages/reviewOptions';

import FeedbackDecisionPage from './pages/feedbackDecision';
import LeaveFeedbackPage from './pages/leaveFeedback';

import 'bootstrap/dist/css/bootstrap.min.css';
import Button, { BackButton } from './components/button';
import axios from 'axios';

//const apiEndpoint = "https://reviewbuddiapi.azurewebsites.net";

export interface Branding {
  id? : string | undefined;
  dateCreated: Date;
  domain?: string | undefined;
  primaryColour?: string | undefined;
  secondaryColour?: string | undefined;
  logoPath?: string | undefined;
  serviceLogoPath: string;
  fontFamilyURL : string,
  fontStack : string,

  //Home page
  homeH1 : string;
  homeH2 : string;

  satisfiedQuestion :string;

  // ReviewOptions Page
  optionsH1 : string;
  optionsP : string;

  option1P :string;
  option1BText :string;
  option1BLink : string;

  feedbackHelperEnabled : true,
  optionHelperH3 : string;
  optionHelperBText : string;

  option2P : string;
  option2BText : string;
  option2BLink : string;

  // FeedbackDecision Page
  feedbackDecisionH1 : string;
  feedbackDecisionP : string;

  // LeaveFeedback Page
  leaveFeedbackH1 : string;

  // ReviewHelper Page
  helperIntroH2 : string;
  helperIntroP : string;

  helperPrompt1 : string;
  helperPrompt2 :string;
  helperPrompt3: string;

  helperCopyH2: string;
  helperCopyP : string;
}

export interface CurrentSession {
  ip: string;
  hostName: string;
  entryPath: string;
}

const DefaultBranding: Branding = {
  id: "ABC",
  dateCreated: new Date(),
  domain: "www.reviewbuddi.com",
  primaryColour: "rgb(146, 40, 142)",
  secondaryColour: "#000000",
  logoPath: "https://reviewbuddiassets.s3.eu-west-2.amazonaws.com/mepslogo.png",
  serviceLogoPath: "",
  fontFamilyURL : "https://fonts.googleapis.com/css?family=Quicksand:400,700",
  fontStack : "'Quicksand',Helvetica,Arial,Lucida,sans-serif",

  //Home page
  homeH1 : "We'd love to hear from you!",
  homeH2 : "Please share your feedback to help us improve our services and help more customers discover us.",

  satisfiedQuestion : "Were you happy with your experience?",

  // ReviewOptions Page
  optionsH1 : "Your review will really help us!",
  optionsP : "Please pick which review website which suits you the best.",

  option1P :"If you have a Google account (and want to leave us a public review)",
  option1BText : "Leave a review on Google",
  option1BLink : "https://search.google.com/local/writereview?placeid=ChIJlfEWjSIbdkgR8qTwyiP_PAA", // MEPS

  feedbackHelperEnabled : true,
  optionHelperH3 : "Want some inspiration? Use our review helper!",
  optionHelperBText : "Build your review here",

  option2P : "Pick TrustPilot (only your name will be displayed)",
  option2BText : "Leave a review on TrustPilot",
  option2BLink : "https://uk.trustpilot.com/evaluate/meps.org.uk",

  // FeedbackDecision Page
  feedbackDecisionH1 : "Leave feedback",
  feedbackDecisionP : "Would you like to leave feedback?",

  // LeaveFeedback Page
  leaveFeedbackH1 : "Leave feedback",

  // ReviewHelper Page
  helperIntroH2 : "Build your review here",
  helperIntroP : "Use our prompts to help write your review in the box below.",

  helperPrompt1 : "What problem did we solve?",
  helperPrompt2 :"How did you find our service?",
  helperPrompt3: "Would you recommend us to others?",

  helperCopyH2: "And, finally...",
  helperCopyP : "Click the copy button, pick a review site and paste your review."
};

const DefaultSession: CurrentSession = {
  ip: "",
  hostName: "",
  entryPath: "",
}

function App() {
  const [branding, setBranding] = useState<Branding>(DefaultBranding);
  const [session, setSession] = useState<CurrentSession>(DefaultSession);

  useEffect(() => {
    document.title = 'ReviewBuddi';
    Fathom.load('GEEBQWGZ');
  }, []);

  return (<>
    <BrowserRouter>
      <div className="App">
        <div className=" sticky-top py-2 mb-4 text-start" style={{ backgroundColor: `${branding.primaryColour}` }}>
          <Container>
            <Row>
              {/* <Col><BackButton /></Col> */}
              {/* <Col><h1 className="text-center mb-0">ReviewBuddi</h1></Col>
              <Col></Col> */}
            </Row>
          </Container>
        </div>
        <Routes>
          <Route path='*' element={<HomePage branding={branding} />} />
          <Route path="/" element={<HomePage branding={branding} />} />
          <Route path="/qr" element={<HomePage branding={branding}  />} />
          <Route path="/reviewHelper" element={<ReviewHelper branding={branding} />} />
          <Route path="/reviewOptions" element={<ReviewOptionsPage branding={branding}  />} />
          <Route path="/feedbackDecision" element={<FeedbackDecisionPage branding={branding}  />} />
          <Route path="/leaveFeedback" element={<LeaveFeedbackPage branding={branding}  />} />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  </>
  );
}

export default App;
