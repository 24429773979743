//Home page
export const homeH1 = "We'd love to hear from you!";
export const homeH2 = "Please share your feedback to help us improve our services and help more customers discover us.";
//export const homeH2 = "Please share your feedback to help us improve our services and help more mums discover us.";

export const satisfiedQuestion = "Were you happy with your experience?";

// ReviewOptions Page
export const optionsH1 = 'Your review will really help us!';
export const optionsP = "Please pick which review website which suits you the best.";

export const option1P = 'If you have a Google account (and want to leave us a public review)';
export const option1BText = "Leave a review on Google";
export const option1BLink = "https://search.google.com/local/writereview?placeid=ChIJlfEWjSIbdkgR8qTwyiP_PAA"; // MEPS

export const feedbackHelperEnabled = true;
export const optionHelperH3 = "Want some inspiration? Use our review helper!"
export const optionHelperBText = "Build your review here";

export const option2P = 'Pick TrustPilot (only your name will be displayed)';
export const option2BText = "Leave a review on TrustPilot";
export const option2BLink = "https://uk.trustpilot.com/evaluate/webhq.uk";

// FeedbackDecision Page
export const feedbackDecisionH1 = "Leave feedback";
export const feedbackDecisionP = "Would you like to leave feedback?";

// LeaveFeedback Page
export const leaveFeedbackH1 = "Leave feedback";

// ReviewHelper Page
export const helperIntroH2 = "Build your review here";
export const helperIntroP = "Use our prompts to help write your review in the box below."

export const helperPrompt1 = "What problem did we solve?";
export const helperPrompt2 = "How did you find our service?";
export const helperPrompt3 = "Would you recommend us to others?";

// export const helperPrompt1 = "What made you want to try an MOT?"; //MMOT
// export const helperPrompt2 = "How did you find your experience?"; //MMOT
// export const helperPrompt3 = "Would you recommend us to other mums?"; //MMOT

// export const helperPrompt1 = "What made you try Pelvi Power?"; //PP
// export const helperPrompt2 = "How are your customers finding it?"; //PP
// export const helperPrompt3 = "Has it helped you grow your physio?"; //PP

export const helperCopyH2 = "And, finally..."
export const helperCopyP = "Click the copy button, pick a review site and paste your review."