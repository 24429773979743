import * as React from "react"
import { Container } from "react-bootstrap";
import * as strings from '../branding/strings';

import {Button, ButtonSecondary} from '../components/button';
import { Branding } from "../App";

type Props = {
  branding: Branding,
}

function ReviewOptions(props: Props) {
  return (
    <Container>
         <h2 className="mb-3">{strings.optionsH1}</h2>
         <p className="mb-4 lead">{strings.optionsP}</p>
         <p className="mb-3">{strings.option1P}</p>
        <div className="container mb-4">
             <Button eventId="" text={strings.option1BText} link={strings.option1BLink} />
        </div>
        <p className="mb-2">{strings.option2P}</p>
        <div className="container">
             <Button eventId="" text={strings.option2BText} link={strings.option2BLink} />
        </div>
        {strings.feedbackHelperEnabled && (
          <div className="container py-5">
            <h4 className="pb-3">{strings.optionHelperH3}</h4>
            <ButtonSecondary eventId="" text={strings.optionHelperBText} link="/reviewHelper" />
          </div>
        )}
     </Container>
  );
}

export default ReviewOptions;