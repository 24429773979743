import * as React from "react"

import Button from '../components/button';

import * as strings from '../branding/strings';
import { Branding } from "../App";

type Props = {
  branding: Branding,
}

function Feedback(props: Props) {
  return (
    <div>
         <h1 className="mb-5">
           {strings.feedbackDecisionH1}
         </h1>
         <p className="mb-3">{strings.feedbackDecisionP}</p>
         <div className="container">
           <a
             href="https:reactjs.org"
             target="_blank"
             rel="noopener noreferrer"
           >
             <Button eventId="" text="Yes" link="/leaveFeedback" />
           </a>
           <a
             href="https:reactjs.org"
             target="_blank"
             rel="noopener noreferrer"
           >
             <Button eventId="" text="No" link="/reviewOptions"></Button>
           </a>
         </div>
     </div>
  );
}

export default Feedback;