import * as React from "react"
import { useNavigate } from "react-router-dom";

import { Container, Form, Button } from "react-bootstrap";
import RangeSlider from "../components/rangeSlider";
import { useRef, useState } from "react";

import * as strings from '../branding/strings';
import { Branding } from "../App";

type Props = {
  branding: Branding,
}

function Review(props: Props) {
  // We set our blank object to the state variable "values"
  const [value, setValue] = useState(2);
  const [text, setText] = useState("");
  const navigate = useNavigate();

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const { name, value } = target;
    event.persist();
    setValue(parseInt(value));
  };

  const buttonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    navigator.clipboard.writeText(text);

    navigate("/reviewOptions");
  };

  return (
      <Container className="">
        <h2 className="mb-3">{strings.helperIntroH2}</h2>
        <p className="mb-4">{strings.helperIntroP}</p>
        <Form className="full-width-form mx-auto">

          {/* <Form.Group className="mb-5" controlId="formBasicEmail">
            <Form.Label>How much guidance would you like?</Form.Label>
            <RangeSlider handleChange={handleSliderChange} />
          </Form.Group> */}

          {value === 2 && (
            <div className="mb-4">
              <div className="text-start">
                <p className="mb-1">- {strings.helperPrompt1}</p>
                <p className="mb-1">- {strings.helperPrompt2}</p>
                <p className="mb-1">- {strings.helperPrompt3}</p>
              </div>
              {/* <Form.Group className="mb-5" controlId="formBasicEmail">
                  <Form.Label>What problem did we solve?</Form.Label>
                  <Form.Control type="textarea" placeholder="What problem did we solve?" />
                </Form.Group>
                <Form.Group className="mb-5" controlId="formBasicEmail">
                  <Form.Label>How did you find our service?</Form.Label>
                  <Form.Control type="textarea" placeholder="How did you find our service?" />
                </Form.Group>
                <Form.Group className="mb-5" controlId="formBasicEmail">
                  <Form.Label>Would you recommend us to others?</Form.Label>
                  <Form.Control type="textarea" placeholder="Would you recommend us to others?" />
                </Form.Group>
                <Form.Group className="mb-5" controlId="formBasicMessage">
                  <Form.Label>Any other thoughts on our service?</Form.Label>
                  <Form.Control as="textarea" rows={4} />
                </Form.Group> */}
            </div>
          )}

            <Form.Group className="mb-4" controlId="formBasicMessage">
              <Form.Control as="textarea" rows={10} value={text} onChange={(e) => setText(e.target.value)} />
            </Form.Group>

            <h2 className="mb-2 text-center">{strings.helperCopyH2}</h2>
            <p className="mb-3 text-lead">{strings.helperCopyP}</p>
          <div className="mb-4">
            <Button variant="btn btn-outline-primary text-start" type="submit" onClick={buttonHandler}>
              Copy to clipboard
            </Button>
          </div>
        </Form>
      </Container>
  );
}

export default Review;