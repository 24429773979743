import * as React from "react"
import { useNavigate } from 'react-router-dom';
import { trackGoal } from 'fathom-client';

type Props = {
  text: string;
  link: string;
  eventId: string;
}

export const Button = (props: Props): React.ReactElement => {
  return (
    <button onClick={() => {
      trackGoal(props.eventId, 0);
      window.location.href = props.link;
    }} 
    type="button" className="btn btn-outline-primary btn-lg px-4 me-md-2 me-2">{props.text}</button>
  );
}

export default Button;

export const ButtonSecondary = (props: Props): React.ReactElement => {
  return (
    <button onClick={() => {
      trackGoal(props.eventId, 0);
      window.location.href = props.link;
    }}
    type="button" className="btn btn-outline-secondary btn-lg px-4 me-2">{props.text}</button>
  );
}

export const ButtonOutline = (props: Props): React.ReactElement => {
  return (
    <button onClick={() => {
      trackGoal(props.eventId, 0);
      window.location.href = props.link;
    }} 
    type="button" className="btn outline-primary btn-lg px-4 me-2">{props.text}</button>
  );
}

export const BackButton = (): React.ReactElement => {
  const navigate = useNavigate();

  return (
    <button onClick={() => navigate(-1)} type="button" className="btn btn-secondary btn-lg">Back</button>
  );
}